
.App {
  background-color: #004fbd;;
  padding: 40px 20px ;
  font-family:'popins';
  color: #323338;
}


.formularioHeader{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

}
.formularioContainer {
  border-radius: 25px;
padding: 20px;
 margin: auto;
 max-width: 600px;
 background-color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
.logo{
  width: 190px;
  height: 80px;
}
}